import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, AlertColor, CircularProgress, Snackbar } from "@mui/material";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import { AppDispatch } from "../../store";
import { changePassword } from "../../networking/networkCalls";

const ResetPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<{
    oldPassword?: string | null;
    newPassword?: string | null;
    confirmPassword?: string | null;
  }>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [snackBarState, setSnackBarState] = useState<{
    show: boolean;
    msg: string;
    severity: AlertColor;
  }>({
    show: false,
    msg: "",
    severity: "success",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const isFormDataValid: () => boolean = () => {
    for (const key in formData) {
      if (
        formData[key] === null ||
        formData[key] === undefined ||
        `${formData[key]}`.trim() === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!isFormDataValid()) {
      setSnackBarState({
        show: true,
        msg: "Please fill in all the required fields",
        severity: "error",
      });
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setSnackBarState({
        show: true,
        msg: "Confirm password does not match with new password",
        severity: "error",
      });
      return;
    }

    setLoading(true);

    const res = await changePassword({
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    });

    if (res) {
      if (res.data.success) {
        setSnackBarState({
          show: true,
          msg: res.data.message || "Password reset was successfull",
          severity: "success",
        });

        setTimeout(() => navigate("/", { replace: true }), 2000);
      } else {
        // failed
        setSnackBarState({
          show: true,
          msg:
            res.data.message || "Something went wrong, please try again later",
          severity: "error",
        });
      }
    } else {
      setSnackBarState({
        show: true,
        msg: "Something went wrong, please try again later",
        severity: "error",
      });
    }

    setLoading(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const data = { ...formData };

    data[`${field}`] = e.target.value;

    setFormData(data);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({ ...snackBarState, show: false });
  };

  return (
    <>
      {/* <Header /> */}
      <Snackbar
        open={snackBarState.show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarState.severity}
          sx={{ width: "100%" }}
        >
          {snackBarState.msg}
        </Alert>
      </Snackbar>
      <main>
        <section className="hero" id="Home">
          <div className="px-4 py-5  text-left">
            <div className="col-lg-6 mx-auto mt-5">
              <div className="loginform">
                {/* <h4
                  className="fw-bold text-shadow animated"
                  data-animation="zoomInDown"
                >
                  Welcome to{" "}
                </h4> */}
                <h3
                  className="mb-3 fw-bold text-shadow animated"
                  data-animation="zoomInDown"
                >
                  Reset Password
                </h3>
                <form onSubmit={onSubmitHandler}>
                  <div className="mb-3">
                    <label className="form-label">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "oldPassword")}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "newPassword")}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleInputChange(e, "confirmPassword")}
                    />
                  </div>
                  {loading && <CircularProgress />}
                  {!loading && (
                    <button
                      type="submit"
                      className="btn btn-pri btn-lg px-4 btn-shadow animated"
                      data-animation="zoomInUp"
                      // onClick={onSubmitHandler}
                    >
                      <h2 className="text-shadow">Submit</h2>
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ResetPassword;
