import React, { useEffect } from "react";
import Header from "../comman_component/Header";
import About from "./About";
import HomeNFT from "./HomeNFT";
import Features from "./Features";
import Footer from "../comman_component/Footer";
import { Link, useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      const element = document.getElementById("/");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.pathname === "/about") {
      const element = document.getElementById("About");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.pathname === "/features") {
      const element = document.getElementById("Features");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Header />
      <main>
        <section className="hero" id="Home">
          <div className="px-4 py-5  text-center">
            <h1
              className="pt-md-3 fw-bold text-shadow animated"
              data-animation="zoomInDown"
            >
              Are you ready to <br />
              <span>RumbleGO?!</span>
            </h1>
            <div className="col-lg-6 mx-auto mt-5">
              <div className=" d-sm-flex justify-content-sm-center">
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <Link to="https://play.google.com/store/search?q=rumblego&c=apps&hl=en">
                <img
                  src="assets/images/googleplay.png"
                  alt="Google Play Store"
                  width="150px"
                />
              </Link>
              <Link to="https://apps.apple.com/in/app/rumblego/id6458740846">
                <img
                  src="assets/images/applestore.png"
                  alt="Apple Store"
                  width="150px"
                />
              </Link>
            </div>
              </div>
              <div className=" d-sm-flex justify-content-sm-center mt-5">
                <button
                  type="button"
                  className="btn btn-pri btn-lg px-4 btn-shadow animated"
                  data-animation="zoomInUp"
                >
                  <Link to="/dapp">
                    <h2 className="text-shadow">Dapp</h2>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <About />
      <Features />
      {/* <HomeNFT /> */}
      <Footer />
    </>
  );
}
